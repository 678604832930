import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getGraphicalAdReport(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Reports/GraphicalAd", filter, authHeaders);
    return res.data;
  },
  async getPerformanceReport(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Reports/Performance", filter, authHeaders);
    return res.data;
  },
};
