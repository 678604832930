<template>
  <div class="category-tab">
    <div class="subheader mb-2">Site</div>
    <div class="list-group mb-3">
      <a
        class="list-group-item list-group-item-action d-flex align-items-center"
        v-if="selected.length == 0"
        @click.prevent
        href="#"
      >
        All
        <small class="ms-auto">({{ allCount }})</small>
      </a>
      <template v-for="site in selected">
        <a
          class="list-group-item list-group-item-action d-flex align-items-center trash-on-hover"
          href="#"
          v-bind:key="'tab-' + site"
          @click.prevent="unselectedSite(site)"
        >
          {{ site }}
          <small class="ms-auto hide-on-hover"> ({{ allSites[site] }}) </small>
          <small class="ms-auto on-hover">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-trash"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="4" y1="7" x2="20" y2="7"></line>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
            </svg>
          </small>
        </a>
      </template>
    </div>
    <div class="mb-3">
      <select
        name="categories"
        class="form-select"
        @change="changedSite($event)"
      >
        <option value="" id="default-select-option" disabled selected="true">
          Select sites
        </option>
        <option v-for="site in sortedSites" v-bind:key="site" :value="site">
          {{ site }} ({{ allSites[site] }})
        </option>
      </select>
    </div>
  </div>
</template>

<style lang="scss">
.trash-on-hover {
  .hide-on-hover {
    display: block;
  }

  .on-hover {
    display: none;
  }
}
.trash-on-hover:hover {
  background: rgba(255, 255, 255, 0.9);

  .hide-on-hover {
    display: none;
  }

  .on-hover {
    display: block;
  }
}
</style>

<script>
export default {
  name: "SiteSelect",
  data: () => {
    return {
      selected: [],
    };
  },
  props: {
    allSites: Object,
  },
  computed: {
    allCount() {
      return Object.values(this.allSites).reduce((sum, c) => sum + c, 0);
    },
    sortedSites() {
      let sorted = Object.keys(this.allSites).sort((a, b) => {
        if (!(a && b)) {
          return 0;
        }
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
      return sorted.filter((s) => this.selected.indexOf(s) === -1);
    },
  },
  methods: {
    changedSite(event) {
      let site = event.target.value;
      event.target.value = "";
      this.selected.push(site);
      this.$emit("update:selected", this.selected);
    },
    unselectedSite(site) {
      this.selected.splice(this.selected.indexOf(site), 1);
      this.$emit("update:selected", this.selected);
    },
  },
};
</script>
